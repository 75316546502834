<template>
    <div class="case_show" >
         <el-row :gutter="20">
        <el-col
          :xs="24"
          :sm="24"
          :md="12"
          :lg="12"
          v-for="(item, index) in caseData"
          :key="index"
          class="case_box"
          @click="goNewsDetail(item.id)"
          >
          <div class="img_box">
              <img :src="`/api/profile/product/${item.route}`" alt="">
          </div>
          <h3 class="case_title">{{item.name}}</h3>
          <p class="descri">{{item.descri}}</p>
          </el-col
        >
      </el-row>
    </div>
</template>

<script>
export default {
    name:'CaseShow',
    props: {
        caseData:Array,
    },
    data(){
        return{
          apiUrl: process.env.VUE_APP_URL,
        }
    },
    methods:{
        goNewsDetail(data){
            this.$router.push({
                path:'/productDetil',
                query:{id:data}
            })
        }
    },


}
</script>

<style scoped lang="less">
    .img_box{
        overflow: hidden;
        height: 367px;
        img{
        transition: .5s;
            width: 100%;
            height:380px;
            &:hover{
                transform: scale(1.1);
            }
        }
    }
    .descri{
        color: #666;
    letter-spacing: 1px;
    line-height: 28px;
    font-size: 16px;
    text-align: left;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    margin-top: 20px;
}
.case_box{
    cursor: pointer;
}
.case_title{
    margin-top: 10px;
    &:hover{
        text-decoration: underline;
    }
}

</style>
